/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/css/main.scss';
import smoothscroll from 'smoothscroll-polyfill';
import { initCookiePolicy } from './src/utils/onetrust';

smoothscroll.polyfill();

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  const { pathname } = location;
  const prevLocation = prevRouterProps?.location;
  if (!prevLocation) {
    window.scrollTo(0, 0);
    return false;
  }
  return pathname !== prevLocation?.pathname;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Execute this function only if I navigate throught the pages: if I land directly in the cookie list page in unnecessary
  if (prevLocation) {
    initCookiePolicy();
  }
};
