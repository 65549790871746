exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-icons-svg-list-js": () => import("./../../../src/pages/icons-svg-list.js" /* webpackChunkName: "component---src-pages-icons-svg-list-js" */),
  "component---src-pages-page-503-js": () => import("./../../../src/pages/page503.js" /* webpackChunkName: "component---src-pages-page-503-js" */),
  "component---src-templates-page-assistance-form-js": () => import("./../../../src/templates/pageAssistanceForm.js" /* webpackChunkName: "component---src-templates-page-assistance-form-js" */),
  "component---src-templates-page-detail-blog-js": () => import("./../../../src/templates/pageDetailBlog.js" /* webpackChunkName: "component---src-templates-page-detail-blog-js" */),
  "component---src-templates-page-detail-product-js": () => import("./../../../src/templates/pageDetailProduct.js" /* webpackChunkName: "component---src-templates-page-detail-product-js" */),
  "component---src-templates-page-generic-embedded-js": () => import("./../../../src/templates/pageGenericEmbedded.js" /* webpackChunkName: "component---src-templates-page-generic-embedded-js" */),
  "component---src-templates-page-generic-js": () => import("./../../../src/templates/pageGeneric.js" /* webpackChunkName: "component---src-templates-page-generic-js" */)
}

